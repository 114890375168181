<template>
  <base-button
    class="flex items-center mr-0.5 transform skew-x-25 py-2 px-2.5 font-semibold rounded-sm focus:outline-none text-sm leading-4"
    :class="$route.name === routeName ? 'bg-custom-gray-9 text-primary-red' : 'bg-custom-gray-10 text-custom-gray-7'"
    @click="$router.push({ name: routeName })"
  >
    <template #default>
      <span class="transform -skew-x-25 truncate">
        {{ navText }}
      </span>
    </template>
  </base-button>
</template>

<script>
import BaseButton from '@/components/generic-components/BaseButton.vue';

export default {
    name: 'NavigationButton',

    components: {
        BaseButton
    },

    props: {
        routeName: {
            type: String,
            required: true,
            description: 'name of route associated with the navigation button'
        },
        navText: {
            type: String,
            default: '',
            description: 'text to be shown on navigation button'
        }
    }
};
</script>
